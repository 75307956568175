<template lang="">
    <Card>
        <h3>Müşteri Ekle</h3>
        <div class="row mt-4">
            <div class="col-md-6 col-12">
                <div class="form-group">
                    <label for="name">Ad</label>
                    <input type="text" id="name" name="name" class="form-control">
                </div>
            </div>
            <div class="col-md-6 col-12">
                <div class="form-group">
                    <label for="surname">Soyad</label>
                    <input type="text" name="surname" id="surname" class="form-control">
                </div>
            </div>
            <div class="col-md-6 col-12">
                <div class="form-group">
                    <label for="email">E-Posta</label>
                    <input type="email" name="email" id="email" class="form-control">
                </div>
            </div>
            <div class="col-md-6 col-12">
                <div class="form-group">
                    <label for="tckn">TCKN</label>
                    <input type="text" name="tckn" id="TCKN" class="form-control">
                </div>
            </div>
            <div class="col-md-6 col-12">
                <div class="form-group">
                    <label for="password">Şifre</label>
                    <input type="password" name="password" id="password" class="form-control">
                </div>
            </div>
            <div class="col-md-6 col-12">
                <div class="form-group">
                    <label for="confirmPassword">Şifre Tekrar</label>
                    <input type="password" name="confirmPassword" id="confirmPassword" class="form-control">
                </div>
            </div>
            <div class="col-md-6 col-12">
                <div class="form-group">
                    <label for="birthdate">Doğum Tarihi</label>
                    <input type="date" name="birthdate" id="birthdate" class="form-control">
                </div>
            </div>
            <div class="col-12 d-flex justify-content-end mt-4">
                <b-button variant="success">Kaydet</b-button>
            </div>
        </div>

    </Card>
</template>
<script>
export default {
    
}
</script>
<style lang="">
    
</style>